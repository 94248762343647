import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { Flex, Button } from "antd";
import {
  FormOutlined,
} from "@ant-design/icons";
import "./styles/chat.css";
import { setDefaultConversation, setNameHistoryConversation } from "../redux/slice.tsx";
import ChatBox from "../components/ChatBoxComponent.tsx";
import Markdown from "react-markdown";
import markdownToPlainText from "../services/filterMarkdown.tsx";

const Chatbot = () => {
  const [title, setTitle] = useState("New chat");
  const name_history_conversation = useSelector((state: any) => state.app.name_history_conversation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (name_history_conversation) {
      setTitle(name_history_conversation);
    }
  }, [name_history_conversation]);

  
  return (
    <Flex
      gap="small"
      vertical
      className="chatbot-container"
      justify="space-between"
      align="stretch"
    >
      <div className="title">
        {markdownToPlainText(title)}
        <Button
          type="primary"
          className="title-button"
          icon={<FormOutlined />}
          style={{
            fontSize: "30px",
            padding:'30px',
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          onClick={() => {
            dispatch(setDefaultConversation("") );
            dispatch(setNameHistoryConversation(""));
          }}
        />
      </div>
      <ChatBox api={'Chat'}/>
      
    </Flex>
  );
};

export default Chatbot;
