import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { apiEmbeddedFile } from "../services/apiMessage.tsx";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const { Dragger } = Upload;



const UploadComponent = () => {
  const collection = useSelector((state:any) => state.app.name_collection);

  const props = {
    name: "file",
    multiple: true,
    customRequest: async (options) => {
      const { file, onSuccess, onError, onProgress } = options;
      if (!collection) {
        message.error("Please provide a collection name before uploading.");
        return;
      }

      const formData = new FormData();
      formData.append("files", file);
      formData.append("name_collection", collection);

      try {
        onProgress({ percent: 50 });
        const response = await apiEmbeddedFile(formData);
        if ((typeof response === "number" && response === 200) || (typeof response === "object" && response === 200)) {
          message.success(`${file.name} uploaded successfully.`);
          onSuccess("Upload success"); // Đánh dấu thành công
        } else {
          message.error(`${file.name} upload failed.`);
          onError(new Error("Upload failed"));
        }
      } catch (error) {
        console.error("Upload error:", error);
        message.error(`${file.name} upload failed: ${error.message || "Unknown error"}`);
        toast.error(`${file.name} upload failed: ${error.message || "Unknown error"}`);
        onError(error);
      }
    },
    beforeUpload: (file) => {
      if (!collection && !file) {
        message.error("Please enter a collection name before uploading.");
        return Upload.LIST_IGNORE; 
      }
      return true; 
    },
    onDrop(e) {
      message.success(`Dropped ${e.dataTransfer.files.length} file(s)`);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">File Format: JSON, LOG, TEXT, HTML</p>
    </Dragger>
  );
};

export default UploadComponent;
