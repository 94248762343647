import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { chatConversationRAG, getCollections } from "../services/apiMessage.tsx";
import { useSelector, useDispatch } from "react-redux";
import { setCollection } from "../redux/slice.tsx";
import { ToastContainer, toast } from "react-toastify";

const DropdownButton: React.FC = () => {
  const dispatch = useDispatch();
  const collections: string[] = useSelector((state: any) => state.app.collections);
  const redux_collection: string = useSelector((state: any) => state.app.collection);
  const [selectedCollection, setSelectedCollection] = useState<string | null>(null);

  useEffect(() => {
    getCollections(dispatch);
    if (selectedCollection) {
      dispatch(setCollection(selectedCollection));
      console.log("Redux Updated:", redux_collection);
    }
  }, [dispatch, redux_collection, selectedCollection]);

  const handleMenuClick = (e: { key: string }) => {
    setSelectedCollection(e.key);
    dispatch(setCollection(e.key));
    console.log("Selected Collection:", e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {collections.map((collection) => (
        <Menu.Item key={collection}>{collection}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
      <Button type="primary">
        <Space>
          {selectedCollection || "Chọn collection"}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DropdownButton;
