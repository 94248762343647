import { Input, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import "./styles/InputComponent.css";
import React from "react";
import InsertImage from "./InsertImage.tsx";

interface ChatInputProps {
  inputText: string;
  setInputText: (text: string) => void;
  sendMessage: () => void;
  isSubmitting: boolean;
  windowWidth: number;
}

const ChatInput: React.FC<ChatInputProps> = ({
  inputText,
  setInputText,
  sendMessage,
  isSubmitting,
  windowWidth,
}) => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        if (e.shiftKey) {
          e.preventDefault();
          setInputText(inputText + `\n`);
        } else if (!isSubmitting) {
          e.preventDefault();
          sendMessage();
          setInputText(""); // Reset input sau khi gửi
        }
      }
    };
  return (
    <div className="chat-input-container">
      <Input
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Type a message..."
        className="chat-input"
        onKeyDown={(e) => handleKeyDown(e)}
        disabled={isSubmitting}
        // style={{ flex: 1 }}
      />
      {windowWidth >= 800 && (
        
        <Button
          type="primary"
          onClick={sendMessage}
          icon={<SendOutlined />}
          disabled={isSubmitting}
        >
        </Button>
        
      )}
    </div>
  );
};

export default ChatInput;
