import React from "react";
import "../ui/styles/ChatRAG.css";
import DropdownButton from "../components/DropdownComponent.tsx";
import ChatBox from "../components/ChatBoxComponent.tsx";
import { Flex } from "antd";


const ChatRAG = () => {
  return (
    <Flex
      vertical
      className="chatbot-container"
      justify="space-between"
      align="stretch"
    >
      <div style={{
        marginLeft:"40px",
        padding:"40px"
      }} className="dropdown-button-container">
        <DropdownButton />
      </div>
      <ChatBox api={'Chat RAG'} />
    </Flex>
  );
};

export default ChatRAG;
