export const CONSTANTS = {
  CHAT_NORMAL: 'Chat Normal',
  CHAT_RAG: 'Chat RAG',
  ADD_FOLDER: 'Add Folder',
  SETTING: 'Setting',
  HISTORY: 'History',
  CHATBOT_URL: 'https://vts.hicas.vn/',
  BASE_URL: 'https://ai.hicas.vn/'
}; 

export const baseURL = "https://ai.hicas.vn/"; 
export const chatbotURL = "https://vts.hicas.vn/";