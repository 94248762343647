import React from "react";
const markdownToPlainText = (markdown) => {
    if (!markdown || typeof markdown !== 'string') {
      return ''; // Trả về chuỗi rỗng nếu markdown không hợp lệ
    }
  
    let plainText = markdown
      // Loại bỏ các ký tự Markdown cơ bản
      .replace(/#+\s/g, '') // Loại bỏ các tiêu đề (#, ##, ###, v.v.)
      .replace(/(\*\*|__)(.*?)\1/g, '$2') // Loại bỏ in đậm (**text** hoặc __text__)
      .replace(/(\*|_)(.*?)\1/g, '$2') // Loại bỏ in nghiêng (*text* hoặc _text_)
      .replace(/~~(.*?)~~/g, '$1') // Loại bỏ gạch ngang (~~text~~)
      .replace(/`([^`]+)`/g, '$1') // Loại bỏ code inline (`text`)
      .replace(/\!\[.*?\]\(.*?\)/g, '') // Loại bỏ hình ảnh ![alt](url)
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1') // Loại bỏ link [text](url), giữ lại text
      .replace(/^\s*[-*+]\s/gm, '') // Loại bỏ danh sách bullet (-, *, +)
      .replace(/^\s*\d+\.\s/gm, '') // Loại bỏ danh sách số (1., 2., v.v.)
      .replace(/\n{2,}/g, '\n') // Thay thế nhiều dòng trống bằng một dòng
      .trim(); // Loại bỏ khoảng trắng thừa ở đầu và cuối
  
    return plainText;
  };

export default markdownToPlainText;