import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./ui/SideBar.tsx";
import { Provider } from "react-redux";
import store from "./redux/store.tsx";
import Chatbot from "./ui/chat.tsx";
import ChatRAG from "./ui/ChatRAG.tsx";
import FileUpload from "./ui/addFolder.tsx";
import "./ui/styles/App.css";
import Settings from "./ui/settings.tsx";
import { CONSTANTS } from "./constants/variables.tsx";
import History from "./ui/History.tsx";
import {ToastContainer, toast} from 'react-toastify';
import {
  MessageOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const App = () => {
  const [currentScreen, setCurrentScreen] = useState("Chat Normal");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [isIframeVisible, setIsIframeVisible] = useState(false);

  useEffect(() => {
    const handleIframeLoad = () => {
      setIframeLoaded(true);
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener("load", handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener("load", handleIframeLoad);
      }
    };
  }, []);

  const renderScreen = () => {
    switch (currentScreen) {
      case CONSTANTS.CHAT_NORMAL:
        return <Chatbot />;
      case CONSTANTS.CHAT_RAG:
        return <ChatRAG />;
      case CONSTANTS.ADD_FOLDER:
        return <FileUpload />;
      case CONSTANTS.SETTING:
        return <Settings />;
      case CONSTANTS.HISTORY:
        return <History setCurrentScreen={setCurrentScreen} />;
      default:
        return <Chatbot />;
    }
  };

  const handleSelectScreen = (screen) => {
    setCurrentScreen(screen);
  };

  const toggleIframe = () => {
    setIsIframeVisible(!isIframeVisible);
  };

  return (
    <Provider store={store}>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div className="div1" style={{ width: "250px", position: "fixed", left: 0, top: 0, height: "100vh" }}>
          <Sidebar onSelectScreen={handleSelectScreen} />
        </div>

        <div className="div2" style={{ marginLeft: "250px", width: "calc(100% - 250px)", height: "100vh" }}>
          {renderScreen()}
        </div>

        {/* Floating button */}
        <button
          onClick={toggleIframe}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            backgroundColor: "#1890ff",
            border: "none",
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "24px",
            zIndex: 100000,
            transition: "all 0.3s ease",
          }}
        >
          {isIframeVisible ? <CloseOutlined /> : <MessageOutlined />}
        </button>

        {/* Iframe container */}
        <div
          style={{
            position: "fixed",
            bottom: "80px",
            right: "20px",
            zIndex: 99999,
            width: "500px",
            height: "610px",
            pointerEvents: "all",
            opacity: isIframeVisible ? 1 : 0,
            visibility: isIframeVisible ? "visible" : "hidden",
            transform: isIframeVisible ? "translateY(0)" : "translateY(20px)",
            transition: "all 0.3s ease",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
          }}
        >
          <iframe
            ref={iframeRef}
            //doi URL hien thi chatbot tai day
            src={CONSTANTS.CHATBOT_URL}
            title="Chatbot Widget"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              backgroundColor: 'white'
            }}
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-downloads"
            allow="microphone; camera; geolocation"
          />
        </div>
        <ToastContainer />
      </div>
    </Provider>
  );
};

export default App;
