import React, { useEffect, useRef, useState } from "react";
import { Bubble } from "@ant-design/x";
import ReactMarkdown from "react-markdown";
import "../ui/styles/ChatRAG.css";
import {
  chatConversationRAG,
  chatStreamConversation,
  getAllMessageConversation,
} from "../services/apiMessage.tsx";
import { UserOutlined, OpenAIFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ChatInput from "./InputComponent.tsx";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface ChatBoxProps {
  api: any;
}

const ChatBox: React.FC<ChatBoxProps> = ({ api }) => {
  const collection = useSelector((state: any) => state.app.collection);
  const [inputText, setInputText] = useState("");
  const date = new Date();
  const dispatch = useDispatch();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const bot_rag_message = useSelector(
    (state: any) => state.app.bot_rag_message
  );
  const [messages, setMessages] = useState<{ text: string; sender: string }[]>(
    []
  );
  const [botTyping, setBotTyping] = useState(false);
  const conversation_id = useSelector(
    (state: any) => state.app.default_conversation
  );
  const bot_message = useSelector((state: any) => state.app.bot_message);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const roles = {
    ai: {
      placement: "start" as "start",
      avatar: {
        icon: <OpenAIFilled />,
        style: { background: "black" },
      },
      typing: { step: 5, interval: 20 },
      style: { maxWidth: 1000 },
    },
    local: {
      placement: "end" as "end",
      avatar: {
        icon: <UserOutlined />,
        style: { background: "black" },
      },
    },
  };

  useEffect(() => {
    const updateMessages = () => {
      const botResponse = api === "Chat" ? bot_message : bot_rag_message;
      if (botResponse && botTyping) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

        setMessages((prevMessages) => {
          if (
            prevMessages.length > 0 &&
            prevMessages[prevMessages.length - 1].sender === "bot"
          ) {
            return [
              ...prevMessages.slice(0, -1),
              { text: botResponse, sender: "bot" },
            ];
          }
          return [...prevMessages, { text: botResponse, sender: "bot" }];
        });
      }
    };

    const fetchHistoryMessages = async () => {
      if (!conversation_id) return;

      try {
        const response = await getAllMessageConversation(conversation_id);
        if (response && response.messages) {
          const formattedMessages = response.messages.map((msg) => ({
            text: msg.content,
            sender: msg.is_bot ? "bot" : "user",
            timestamp: new Date(msg.timestamp).toLocaleTimeString(),
          }));

          setMessages(formattedMessages);
        }
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      }
    };

    fetchHistoryMessages();
    updateMessages();
  }, [
    bot_message,
    bot_rag_message,
    botTyping,  
    conversation_id,
    messages.length,
    api,
  ]);

  const sendMessage = async (inputText: string) => {
    if (!inputText.trim()) return;

    const newMessage = { text: inputText, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setBotTyping(true);

    try {
      if (api === "Chat") {
        await chatStreamConversation(
          dispatch,
          inputText,
          setBotTyping,
          conversation_id
        );
      } else {
        await chatConversationRAG(dispatch, collection, inputText);
      }
    } catch (error) {
      console.error("Lỗi khi gửi tin nhắn:", error);
    } finally {
      setBotTyping(false);
      setInputText("");
    }
  };

  const formatContent = (content: string, maxLength: number = 300) => {
    if (!content || typeof content !== "string") {
      return (
        <div className="text-block">
          <ReactMarkdown>Invalid content</ReactMarkdown>
        </div>
      );
    }
  
    // Tách nội dung bằng ```
    const parts = content.split("```");
  
    // Xử lý từng phần
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // Phần code block
        const trimmedPart = part.trim();
        if (!trimmedPart) {
          return (
            <div key={index} className="code-block">
              <pre>
                <code>Empty code block</code>
              </pre>
            </div>
          );
        }
  
        // Tách dòng đầu tiên để lấy ngôn ngữ
        const lines = trimmedPart.split("\n");
        let language = "plaintext"; // Mặc định là plaintext
        let codeContent = trimmedPart; // Nội dung code mặc định
  
        if (lines.length > 0) {
          const firstLine = lines[0].trim().toLowerCase();
          // Kiểm tra các ngôn ngữ phổ biến
          if (firstLine === "jsx" || firstLine === "js") {
            language = "javascript";
          } else if (firstLine === "go") {
            language = "go";
          } else if (firstLine === "python") {
            language = "python";
          } else if (firstLine === "html") {
            language = "html";
          } else if (firstLine === "css") {
            language = "css";
          } else if (firstLine === "sql") {
            language = "sql";
          } else if (firstLine === "json") {
            language = "json";
          } else if (firstLine === "tsx" || firstLine === "ts") {  
            language = "typescript";
          } else if (firstLine === "bash") {
            language = "bash";
          } else if (firstLine === "shell") {
            language = "shell";
          }else{
            language = firstLine;
          }
  

          if (language !== "plaintext") {
            codeContent = lines.slice(1).join("\n").trim();
          }
        }
  
        return (
          <div key={index} className="code-block">
            <SyntaxHighlighter language={ language} style={vscDarkPlus} wrapLines={true}>
              {codeContent}
            </SyntaxHighlighter>
          </div>
        );
      }
  
      // Phần text block: Chia dòng nếu vượt maxLength
      const text = part.trim();
      if (text.length <= maxLength) {
        return (
          <div key={index} className="text-block">
            <p>
              {text}
            </p>
          </div>
        );
      }
  
      const words = text.split("");
      let formattedText = "";
      let currentLine = "";
      for (const word of words) {
        if ((currentLine + word).length > maxLength) {
          formattedText += currentLine.trim() + "\n"; // Thêm xuống dòng
          currentLine = word + ""; // Bắt đầu dòng mới
        } else {
          currentLine += word + ""; // Thêm khoảng trắng giữa các từ
        }
      }
      formattedText += currentLine.trim();
      return (
        <div key={index} className="text-block">
          <ReactMarkdown>{formattedText}</ReactMarkdown>
        </div>
      );
    });
  };

  return (
    <div className="chatbox">
      <div className="chatbox-list">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender === 'user' ? 'user-message' : 'bot-message'}`}>
            {formatContent(message.text)}
          </div>
        ))}
      </div>
      <div ref={messagesEndRef} />

      <ChatInput
        inputText={inputText}
        setInputText={setInputText}
        sendMessage={()=>{
          sendMessage(inputText)
        }}
        isSubmitting={botTyping}
        windowWidth={windowWidth}
      />
    </div>
  );
};

export default ChatBox;