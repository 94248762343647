import React, { useState } from "react";
import { Input, Button, Card, Typography, message, Row, Col } from "antd";
import UploadComponent from "../components/UploadComponent.tsx";
import { useDispatch } from "react-redux";
import { setNameFileCollection } from "../redux/slice.tsx";
import "./styles/addFolder.css"; // Thêm file CSS mới

const { Title } = Typography;

const FileUpload = () => {
  const [nameCollection, setNameCollection] = useState("");
  const dispatch = useDispatch();

  return (
    <div className="upload-container">
      <Title level={2} className="upload-title">File Upload</Title>
      <Row gutter={[24, 24]} className="upload-row">
        <Col xs={24} sm={12} md={10} lg={8}>
          <Card className="upload-card">
            <Title level={4}>Collection Settings</Title>
            <Input
              placeholder="Enter collection name"
              value={nameCollection}
              onChange={(e) => setNameCollection(e.target.value)}
              className="upload-input"
            />
            <Button
              type="primary"
              block
              className="upload-button"
              onClick={() => {
                dispatch(setNameFileCollection(nameCollection));
                message.success("Collection name saved");
              }}
            >
              Save Name
            </Button>
          </Card>
        </Col>

        {/* File Upload */}
        <Col xs={24} sm={12} md={10} lg={8}>
          <Card className="upload-card">
            <Title level={4}>Upload Files</Title>
            <UploadComponent />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FileUpload;