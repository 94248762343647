import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bot_rag_message:'',
  bot_message:'', // Ensure this key matches useSelector
  collections:[],
  collection:'',
  name_collection:'',
  delete_collection:'',
  default_conversation:'',
  name_history_conversation:'',
  isSidebarOpen:true
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setBotMessage(state, action){
      state.bot_message = action.payload;
    },
    setCollections(state,action){
        state.collections = action.payload;
    },
    setCollection(state,action){
        state.collection = action.payload;
    },
    setBotRagMessage(state,action){
        state.bot_rag_message = action.payload;
    },
    setNameFileCollection(state,action){
        state.name_collection = action.payload;
    },
    setDeleteCollection(state,action){
        state.delete_collection = action.payload;
    },
    setDefaultConversation(state,action){
        state.default_conversation = action.payload;
    },
    setIsSidebarOpen(state,action){
        state.isSidebarOpen = action.payload;
    },
    setNameHistoryConversation(state,action){
        state.name_history_conversation = action.payload;
    }
  },
});

export const { setDeleteCollection,setBotMessage,setCollections,setCollection,setBotRagMessage,setNameFileCollection,setDefaultConversation,setIsSidebarOpen,setNameHistoryConversation } = appSlice.actions;
export default appSlice.reducer;
