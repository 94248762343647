import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  apiListConversations,
  deleteConversation,
  getAllMessageConversation,
} from "../services/apiMessage.tsx";
import { setDefaultConversation, setNameHistoryConversation } from "../redux/slice.tsx";
import { Empty, Spin, message } from "antd";
import markdownToPlainText from "../services/filterMarkdown.tsx";
import "./styles/History.css";

const History = ({ setCurrentScreen }) => {
  const [conversations, setConversations] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const data = await apiListConversations();
        setConversations(data);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, []);

  const handleDelete = async (conversationId) => {
    try {
      await deleteConversation(conversationId);
      setConversations((prev) =>
        prev.filter((conv) => conv.conversation_id !== conversationId)
      );
      message.success("Delete done conversation");
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const handleClick = (conv) => {
    setLoadingId(conv.conversation_id);

    setTimeout(() => {
      dispatch(setDefaultConversation(conv.conversation_id));
      dispatch(setNameHistoryConversation(conv.title));
      getAllMessageConversation(conv.conversation_id);
      setCurrentScreen("Chat Normal");
      setLoadingId(null);
    }, 1500);
  };

  return (
    <div className="history-container">
      <h1 className="history-title">History conversations</h1>
      {conversations && conversations.length > 0 ? (
        <ul className="conversation-list">
          {conversations.map((conv) => (
            <li key={conv.conversation_id} className="conversation-item">
              <div className="conversation-content">
                <h3>{markdownToPlainText(conv.title)}</h3>
                <p>{conv.content}</p>
              </div>
              <div className="conversation-actions">
                <button
                  className="open-btn"
                  onClick={() => handleClick(conv)}
                  disabled={loadingId === conv.conversation_id}
                >
                  {loadingId === conv.conversation_id ? <Spin size="small" /> : "Open"}
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDelete(conv.conversation_id)}
                  disabled={loadingId === conv.conversation_id}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty style={{ color: "white" }} />
        </div>
      )}
    </div>
  );
};

export default History;