import { configureStore } from "@reduxjs/toolkit";
import appReducer from './slice.tsx';


const store = configureStore({
    reducer:{
        app:appReducer,
    }
})

export default store;