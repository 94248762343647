import axios, { AxiosInstance } from "axios";
import { Dispatch } from "redux";
import {
  setBotMessage,
  setBotRagMessage,
  setCollections,
} from "../redux/slice.tsx";
import { CONSTANTS } from "../constants/variables.tsx";
import { toast } from "react-toastify";


interface ChatResponse {
  result?: {
    token?: string;
  };
}
// Utility functions
const extractCodeBlocks = (text: string): string => {
  const marker = "```";
  const startIdx = text.indexOf(marker);

  if (startIdx === -1) return text.trim();

  const endIdx = text.indexOf(marker, startIdx + marker.length);
  if (endIdx === -1) return text.trim();

  return text.slice(startIdx + marker.length, endIdx).trim();
};

//Extract and format text API
const extractAndFormatText = (dataString: string): string => {
  if (!dataString) {
    console.error("extractAndFormatText: Received invalid dataString.");
    return "";
  }

  const lines = dataString.trim().split("\n");
  const tokens: string[] = [];

  lines.forEach((line, index) => {
    try {
      const json: ChatResponse = JSON.parse(line);
      if (json?.result?.token) {
        tokens.push(extractCodeBlocks(json.result.token));
      }
    } catch (error) {
      console.error(`Lỗi parse JSON ở dòng ${index + 1}:`, error);
    }
  });

  return tokens.join(" ").trim();
};

// API Service configuration
const createApiService = (): AxiosInstance => {
  return axios.create({
    baseURL: CONSTANTS.BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};


// Chat stream APIs
export const chatStreamConversation = async (
  dispatch: Dispatch,
  content: string,
  setBotTyping: (typing: boolean) => void,
  conversation_id?: string
) => {
  try {
    const apiUrl = conversation_id
      ? `${CONSTANTS.BASE_URL}conversation/${conversation_id}/responses`
      : `${CONSTANTS.BASE_URL}conversation/new`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: content }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (!response.body) {
      throw new Error("Response body is empty");
    }

    setBotTyping(true);

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let accumulatedMessage = "";
    let isCancelled = false;

    const processChunk = async (): Promise<string> => {
      const { done, value } = await reader.read();
      if (done || isCancelled) return accumulatedMessage;

      const chunk = decoder.decode(value, { stream: true });
      accumulatedMessage += (accumulatedMessage ? "" : "") + extractAndFormatText(chunk);
      dispatch(setBotMessage(accumulatedMessage));
      console.log(accumulatedMessage);

      return processChunk();
    };

    const cleanup = () => {
      isCancelled = true;
      reader.cancel().catch(console.error);
    };

    return { cleanup, accumulatedMessage: await processChunk() };
  } catch (error) {
    toast.error(`${error}`);
  }
};

export const chatConversationRAG = async (
  dispatch: Dispatch,
  collection: string,
  message: string
): Promise<any> => {
  try {
    const response = await createApiService().post("/chat/rag", {
      message,
      name_collection: collection,
    });
    dispatch(setBotRagMessage(response.data.rep));
    return response.data;
  } catch (error) {
    toast.error(`${error}`);
  }
};


// Collection Management APIs
export const getCollections = async (dispatch: Dispatch) => {
  try {
    const response = await createApiService().get("/dbembed/get_collections");
    const listCollections = response.data.message.collections.map(
      (item: { name: string }) => item.name
    );
    dispatch(setCollections(listCollections));
    return listCollections;
  } catch (error) {
    // console.error("Error fetching collections:", error);
    toast.error(`${error}`);
    // throw error;
  }
};

export const apiEmbeddedFile = async (data: FormData) => {
  try {
    const response = await fetch(
      `${CONSTANTS.BASE_URL}/embed/embed_database`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    );
    return response.status;
  } catch (error) {
    // console.error("Upload failed:", error);
    toast.error(`${error}`);
    // throw error;
  }
};

//Remove collection API
export const deleteCollection = async (collectionName: string): Promise<number> => {
  try {
    const response = await createApiService().delete("/dbembed/delete_collection", {
      data: {
        name_collection: collectionName,
      },
    });
    return response.status;
  } catch (error: any) {
    // console.error("Error deleting collection:", error);
    toast.error(`${error}`);
    return 500;
  }
};

// Conversation Management APIs
export const apiListConversations = async () => {
  try {
    const response = await createApiService().get(
      "/conversation/get_all_conversations"
    );
    return response.data.conversations;
  } catch (error) {
    // console.error("Error fetching conversations:", error);
    toast.error(`${error}`);
    // throw error;
  }
};

//Delete conversation API
export const deleteConversation = async (conversation_id: string) => {
  try {
    const response = await createApiService().delete(
      `/conversation/${conversation_id}`
    );
    return response.data;
  } catch (error) {
    // console.error("Error deleting conversation:", error);
    toast.error(`${error}`);
    // throw error;
  }
};

//Get all message conversation API
export const getAllMessageConversation = async (conversation_id: string) => {
  try {
    const response = await createApiService().get(
      `/conversation/${conversation_id}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error fetching conversation messages:", error);
    toast.error(`${error}`);
    // throw error;
  }
};
