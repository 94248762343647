import React from "react";
import "./styles/settings.css";
import { Flex, Button, Checkbox, message, Empty } from "antd";
import { DeleteFilled, ReloadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCollection, getCollections } from "../services/apiMessage.tsx";
import { setDeleteCollection } from "../redux/slice.tsx";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const ListCollections = () => {
  const collections = useSelector((state: any) => state.app.collections);
  const delete_collection = useSelector(
    (state: any) => state.app.delete_collection
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getCollections(dispatch);
  }, [delete_collection]);

  return (
    <div className="collection-list">
      {collections.length === 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty
            style={{
              color: "white",
            }}
          />
        </div>
      ) : (
        collections.map((collection) => (
          <div className="collection-item" key={collection}>
            <Checkbox
              onChange={() => {
                dispatch(setDeleteCollection(collection));
              }}
            >
              {collection}
            </Checkbox>
          </div>
        ))
      )}
    </div>
  );
};

const DropCollection = () => {
  const [size, setSize] = useState<SizeType>("large");
  const delete_collection = useSelector(
    (state: any) => state.app.delete_collection
  );
  const dispatch = useDispatch();
  const handleRefresh = () => {
    getCollections(dispatch);
    message.info("Collections refreshed!");
  };
  return (
    <Flex gap="middle" vertical className="delete-section">
      <p>Delete collection</p>
      <small>(*) Only delete one collection at a time</small>
      <ListCollections />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <Button
          type="primary"
          shape="default"
          icon={<DeleteFilled />}
          size={size}
          onClick={async () => {
            const rsp = await deleteCollection(delete_collection);
            if (rsp === 200) {
              message.success("Delete done");
            } else {
              message.error("Delete failed");
            }
          }}
        >
          Delete
        </Button>
        <Button
          type="primary"
          shape="default"
          icon={<ReloadOutlined />}
          size={size}
          onClick={async () => handleRefresh()}
        >
          Refresh
        </Button>
      </div>
    </Flex>
  );
};

const Settings = () => {
  return (
    <div className="settings-wrapper">
      <h1 className="settings-header">Settings</h1>
      <DropCollection />
    </div>
  );
};

export default Settings;
