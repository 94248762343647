import React, { useState } from "react";
import { Home, Settings, MessageCircle, User,History } from "lucide-react";
import "./styles/SideBar.css"; 

const Sidebar = ({ onSelectScreen }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState("Chat Normal");

  const menuItems = [
    { icon: <Home size={20} />, label: "Chat Normal" },
    { icon: <MessageCircle size={20} />, label: "Chat RAG" },
    { icon: <User size={20} />, label: "Add Folder" },
    { icon: <Settings size={20} />, label: "Setting" },
    { icon: <History size={20} />, label:"History" }
  ];

  const handleScreenChange = (label) => {
    setActiveScreen(label);
    onSelectScreen(label); 
  };

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>

        <nav className="sidebar-menu">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`menu-item ${activeScreen === item.label ? "active" : ""}`}
              onClick={() => handleScreenChange(item.label)}
            >
              {item.icon}
              {isOpen && <span className="menu-label">{item.label}</span>}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};


export default Sidebar;
